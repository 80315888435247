import flow from 'lodash/flow'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import NoTabsWrapper from './components/wrapper-notabs'
import Skeleton from '@material-ui/lab/Skeleton'
import { ThemeWrapper } from './config/theme'

const Site = React.lazy(() =>
  import(/* webpackChunkName: 'site'*/ './site.jsx'),
)

function loadScriptViaPromise(src) {
  var script = document.createElement('script')
  script.src = src
  script.async = true
  script.defer = true
  let _resolve, _reject
  return new Promise((resolve, reject) => {
    _resolve = resolve
    _reject = reject
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  }).finally(() => {
    script.removeEventListener('load', _resolve)
    script.removeEventListener('error', _reject)
  })
}

function loadGapi() {
  return loadScriptViaPromise('https://apis.google.com/js/platform.js')
}

function SiteSkeleton() {
  return (
    <Skeleton variant='rect' width='100%' height='100%'/>
  )
}

function Loading(props) {
  const classes = {}
  console.log('LOCATION:', window.location.pathname) 
  return window.location.pathname.startsWith('/embed') ?
  (
    <SiteSkeleton/>
  ) :
  (
    <NoTabsWrapper>
      <SiteSkeleton/>
    </NoTabsWrapper>
  )
}

function Load(props) {
  return (
    <Suspense fallback={<Loading/>}>
      <Site {...props}/>
    </Suspense>
  )
}

const App = flow(
  ThemeWrapper,
  //  RouterWrapper,
)(Load)

loadGapi().then(() => {
  ReactDOM.render(<App open={true} />, document.getElementById('app'))
})

